import cn from 'classnames'
import s from './CheckoutLayout.module.scss'
import React, { FC, useEffect, useCallback } from 'react'
import { useUI, ToastType } from '@corratech/pylot-ui/context'
import { useAuthUI } from '@corratech/pylot-auth-manager'
import { useTranslation } from 'next-i18next'
import { AuthWrapper } from '@components/common/AuthWrapper'
import { useRouter } from 'next/router'
import { validate as EmailValidate } from 'email-validator'
import { CheckoutNav, CheckoutFooter } from '@components/common'
import { CustomerImpersonation } from '@components/CustomerImpersonation'

interface Props {
    authRequired: boolean
    pageProps: any
}

enum MODAL_VIEW_ENUMS {
    LOGIN_VIEW = 'LOGIN_VIEW',
    SIGNUP_VIEW = 'SIGNUP_VIEW',
    FORGOT_VIEW = 'FORGOT_VIEW',
    RESET_VIEW = 'PASSWORD_RESET_VIEW'
}
enum MODAL_HASH_ENUMS {
    LOGIN_VIEW = 'show-login-form',
    SIGNUP_VIEW = 'show-signup-form',
    FORGOT_VIEW = 'show-forgot-password',
    RESET_VIEW = 'show-reset-form',
    GUEST = 'session-expired'
}

const CheckoutLayout: FC<Props> = ({ children, authRequired }) => {
    const { openToast } = useUI()
    const { setModalView, openAuthModal } = useAuthUI()
    const { t } = useTranslation()
    const router = useRouter()

    const onHashChange = useCallback(() => {
        if (typeof window !== 'undefined' && window.location.hash) {
            switch (window.location.hash.replace('#', '')) {
                case MODAL_HASH_ENUMS.LOGIN_VIEW:
                    setModalView(MODAL_VIEW_ENUMS.LOGIN_VIEW)
                    openAuthModal()
                    break
                case MODAL_HASH_ENUMS.SIGNUP_VIEW:
                    setModalView(MODAL_VIEW_ENUMS.SIGNUP_VIEW)
                    openAuthModal()
                    break
                case MODAL_HASH_ENUMS.FORGOT_VIEW:
                    setModalView(MODAL_VIEW_ENUMS.FORGOT_VIEW)
                    openAuthModal()
                    break
                case MODAL_HASH_ENUMS.RESET_VIEW:
                    {
                        const url = new URL(router.asPath, location.origin)
                        const email = url.searchParams.get('email')
                        const token = url.searchParams.get('token')
                        if (email && token && EmailValidate(email)) {
                            setModalView(MODAL_VIEW_ENUMS.RESET_VIEW, {
                                email,
                                token
                            })
                            openAuthModal()
                        }
                    }
                    break
                case MODAL_HASH_ENUMS.GUEST:
                    if (
                        window?.localStorage.getItem('session_invalid') === '1'
                    ) {
                        openToast(
                            t('Your session expired. Please sign in again.'),
                            ToastType.Warning
                        )
                        window.localStorage.removeItem('session_invalid')
                        router.replace('/')
                    }
                    break
            }
        }
    }, [])

    useEffect(() => {
        onHashChange()
        window.addEventListener('hashchange', onHashChange, false)

        return () =>
            window.removeEventListener('hashchange', onHashChange, false)
    }, [])

    return (
        <div className={cn(s.root)}>
            <CheckoutNav />
            <main className="fit" id="contentarea">
                <AuthWrapper authRequired={authRequired}>
                    <CustomerImpersonation />
                    {children}
                </AuthWrapper>
            </main>
            <CheckoutFooter />
        </div>
    )
}

export default CheckoutLayout
