import { FC, useState } from 'react'
import dynamic from 'next/dynamic'
import { Container, Button } from '@corratech/pylot-ui'
import { ConfirmPageLeave } from '@components/common'
import { useTranslation } from 'next-i18next'
import { Bag, Phone } from '@components/icons'
import { useUI } from '@corratech/pylot-ui/context'
import { useRouter } from 'next/router'
import { SimpleCartItem } from '@pylot-data/pylotschema'
import { useCart } from '@corratech/pylot-cart-manager'
import { useGlobalProps } from '@pylot-data/hooks/props/useGlobalProps'
import Link from 'next/link'
import cn from 'classnames'
import s from './CheckoutNav.module.scss'
import { ImpersonationBanner } from '@components/CustomerImpersonation'

const Logo = dynamic(() => import('@components/icons/Logo'))

const NAVBAR_ID = 'navbar'

const CheckoutNav: FC = () => {
    const [
        isCheckoutModalConfirmationOpen,
        setIsCheckoutModalConfirmationOpen
    ] = useState(false)
    const [
        isCheckoutDropdownConfirmationOpen,
        setIsCheckoutDropdownConfirmationOpen
    ] = useState(false)
    const [isCartModalConfirmationOpen, setIsCartModalConfirmationOpen] =
        useState(false)
    const [isCartDropdownConfirmationOpen, setIsCartDropdownConfirmationOpen] =
        useState(false)

    const { t } = useTranslation('common')
    const router = useRouter()
    const { data: cartData } = useCart()
    const { toggleSidebar } = useUI()
    const { cartConfig } = useGlobalProps()

    const cartItemsCount = cartData?.data?.cart?.items?.reduce(
        (itemCount, item) => {
            if (item) {
                return itemCount + (item as SimpleCartItem)?.quantity
            }
            //return 0 to make the itemCount unchanged and prevent NAN return type
            return 0
        },
        0
    )

    const redirectCart = () => router.push('/cart')

    const handleOpenCart = () => {
        toggleSidebar()
        setIsCartModalConfirmationOpen(false)
        setIsCartDropdownConfirmationOpen(false)
    }

    const handleLeaveCheckoutConfirmation = () => {
        setIsCheckoutModalConfirmationOpen(true)
        setIsCheckoutDropdownConfirmationOpen(true)
    }

    return (
        <div className={cn(s.root)}>
            <ImpersonationBanner />
            <Container id={NAVBAR_ID} clean className={s['nav-container']}>
                <div className={s['nav-container-inner']}>
                    <div className={s['nav-section']}>
                        <div className="relative">
                            <Button
                                aria-label={t('USGB Logo')}
                                variant="link"
                                className={s.logo}
                                onClick={handleLeaveCheckoutConfirmation}
                            >
                                <Logo />
                                <span className="sr-only">
                                    {t('USGB Logo')}
                                </span>
                            </Button>
                            <ConfirmPageLeave
                                onModalConfirmationChange={
                                    setIsCheckoutModalConfirmationOpen
                                }
                                isModalConfirmationOpen={
                                    isCheckoutModalConfirmationOpen
                                }
                                onDropdownConfirmationChange={
                                    setIsCheckoutDropdownConfirmationOpen
                                }
                                isDropdownConfirmationOpen={
                                    isCheckoutDropdownConfirmationOpen
                                }
                                customDropdownClassName={
                                    s['confirmation-dropdown-logo']
                                }
                                onAcceptLeavePage={() => router.push('/')}
                            />
                        </div>
                        <div className="relative flex mt-1 md:mt-3">
                            <Link
                                href="tel:+1-800-775-3504"
                                className={cn(s['phone-link'])}
                            >
                                <Phone />
                                <span>{t('Call Us')}</span>
                            </Link>
                            <Button
                                className={cn(s['bag-btn'])}
                                variant="link"
                                type="button"
                                onClick={redirectCart}
                                aria-label={t('Cart')}
                            >
                                <span aria-hidden="true">
                                    <Bag />
                                </span>
                                <span
                                    className={cn(
                                        s['cart-label'],
                                        'cart-label'
                                    )}
                                >
                                    {t('Cart')}
                                </span>
                                <span
                                    className={cn(
                                        s['cart-count'],
                                        'cart-count',
                                        cartItemsCount && 'has-count'
                                    )}
                                >
                                    {cartItemsCount ?? 0}
                                </span>
                            </Button>
                            <ConfirmPageLeave
                                onModalConfirmationChange={
                                    setIsCartModalConfirmationOpen
                                }
                                isModalConfirmationOpen={
                                    isCartModalConfirmationOpen
                                }
                                onDropdownConfirmationChange={
                                    setIsCartDropdownConfirmationOpen
                                }
                                isDropdownConfirmationOpen={
                                    isCartDropdownConfirmationOpen
                                }
                                onAcceptLeavePage={
                                    cartConfig.cartType === 'cart_page'
                                        ? redirectCart
                                        : handleOpenCart
                                }
                                acceptLeavePageLabel={t('Go to Cart')}
                                customDropdownClassName={
                                    s['confirmation-dropdown-cart']
                                }
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default CheckoutNav
