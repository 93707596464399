const Phone = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.7437 8.60014C11.2312 8.38139 10.6375 8.52514 10.2844 8.95639L9.24687 10.2251C7.80937 9.39076 6.60938 8.19076 5.775 6.75326L7.04063 5.71889C7.47188 5.36576 7.61875 4.77201 7.39687 4.25951L5.89687 0.759512C5.6625 0.209512 5.07188 -0.0967382 4.4875 0.0282618L0.9875 0.778262C0.4125 0.900137 0 1.40951 0 2.00014C0 9.22514 5.475 15.1751 12.5031 15.922C12.8094 15.9533 13.1156 15.9783 13.4281 15.9908H13.4312C13.6219 15.997 13.8094 16.0033 14 16.0033C14.5906 16.0033 15.1 15.5908 15.2219 15.0158L15.9719 11.5158C16.0969 10.9314 15.7906 10.3408 15.2406 10.1064L11.7406 8.60639L11.7437 8.60014ZM13.7969 14.5001C7.05625 14.3908 1.60938 8.94389 1.50312 2.20326L4.60313 1.53764L5.94688 4.67514L4.825 5.59389C4.25625 6.05951 4.10938 6.86889 4.47813 7.50639C5.44375 9.17201 6.83125 10.5595 8.49687 11.5251C9.13437 11.8939 9.94375 11.747 10.4094 11.1783L11.3281 10.0564L14.4656 11.4001L13.7969 14.5001Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default Phone
