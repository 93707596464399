import s from '@commonStyles/footer/Footer.module.scss'
import { RichContent } from '@corratech/pylot-rich-content'
import { useCmsBlocks } from '@pylot-data/hooks/cms/use-cms-blocks'

const CheckoutFooter = (): JSX.Element | null => {
    const { items } = useCmsBlocks(['footer-checkout'], {
        revalidateOnMount: true,
        revalidateOnFocus: false
    })

    if (!items) return null

    return (
        <footer>
            <div className={s['checkout-footer-container']}>
                <RichContent html={items?.[0]?.content} />
            </div>
        </footer>
    )
}

export default CheckoutFooter
