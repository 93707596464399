import { ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { Dropdown } from '@corratech/pylot-dropdown'
import { Button, Modal } from '@corratech/pylot-ui'
import { isMobileScreen } from '@lib/utils/mediaQuery'
import { useTranslation } from 'next-i18next'
import s from './ConfirmPageLeave.module.scss'
import cn from 'classnames'

export interface Props {
    onModalConfirmationChange: (value: boolean) => void
    isModalConfirmationOpen: boolean
    onDropdownConfirmationChange: (value: boolean) => void
    isDropdownConfirmationOpen: boolean
    onAcceptLeavePage: () => void
    acceptLeavePageLabel?: string
    declineLeavePageLabel?: string
    modalConfirmationLabel?: string
    customDropdownClassName?: string
}

const ConfirmPageLeave = ({
    onModalConfirmationChange,
    isModalConfirmationOpen,
    onDropdownConfirmationChange,
    isDropdownConfirmationOpen,
    onAcceptLeavePage,
    acceptLeavePageLabel,
    declineLeavePageLabel,
    modalConfirmationLabel,
    customDropdownClassName
}: Props): ReactElement => {
    const { t } = useTranslation('common')
    const isMobile = isMobileScreen()

    const acceptLabel = acceptLeavePageLabel
        ? acceptLeavePageLabel
        : t('Go to Homepage')
    const declineLabel = declineLeavePageLabel
        ? declineLeavePageLabel
        : t('Stay in Checkout')
    const modalLabel = modalConfirmationLabel
        ? modalConfirmationLabel
        : t('Are you sure you want to leave checkout?')

    return !isMobile ? (
        <div className="hidden md:block">
            {isDropdownConfirmationOpen ? (
                <Dropdown
                    className={cn(
                        s['confirmation-dropdown'],
                        customDropdownClassName
                    )}
                    startsOpen
                    arrow
                    willClose={() => onDropdownConfirmationChange(false)}
                    collapseOnContentClick
                    // @ts-ignore
                    openedDisplay={null}
                    // @ts-ignore
                    closedDisplay={null}
                >
                    <div className={s['dropdown-content']}>
                        <p className="mb-4">{modalLabel}</p>
                        <div className={s['buttons-container']}>
                            <Button
                                className={s['confirmation-button']}
                                variant="secondary"
                                onClick={() =>
                                    onDropdownConfirmationChange(false)
                                }
                            >
                                {declineLabel}
                            </Button>
                            <Button
                                className={s['confirmation-button']}
                                onClick={onAcceptLeavePage}
                            >
                                {acceptLabel}
                            </Button>
                        </div>
                    </div>
                </Dropdown>
            ) : null}
        </div>
    ) : (
        <div className="block md:hidden">
            {isModalConfirmationOpen &&
                createPortal(
                    <Modal
                        className={s['modal-container']}
                        onClose={() => onModalConfirmationChange(false)}
                        open={isModalConfirmationOpen}
                        allowScrolling
                        closeOnOverlayClick
                    >
                        <div className={s['dropdown-content']}>
                            <p className="mb-4">
                                {t('Are you sure you want to leave checkout?')}
                            </p>
                            <div className={s['buttons-container']}>
                                <Button
                                    className={s['confirmation-button']}
                                    variant="secondary"
                                    onClick={() =>
                                        onModalConfirmationChange(false)
                                    }
                                >
                                    {declineLabel}
                                </Button>
                                <Button
                                    className={s['confirmation-button']}
                                    onClick={onAcceptLeavePage}
                                >
                                    {acceptLabel}
                                </Button>
                            </div>
                        </div>
                    </Modal>,
                    document.body
                )}
        </div>
    )
}

export default ConfirmPageLeave
